body{
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#root{
  min-height: 100vh;
  max-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  overflow: hidden;
}