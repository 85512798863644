.main-bg {
    background-color: #37373f !important;
}

.text-dark-custom {
    color: #000 !important;
}

.login-btn {
    background-color: #7b00f7 !important;
}

.main-bg-custom {
    background-color: #efeff1 !important;
}

input {
    background-color: #fafafa !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.bg-secondary-custom {
    background-color: #7b809a0f !important;
}

.nav-tabs .nav-link.active {
    color: #4CAF50 !important;
    border-color: #4CAF50 !important;
}

@media (min-width: 576px) {
    .h-sm-auto {
        height: auto !important;
    }

}

@media (max-width: 576px) {
    .vh-sm-100 {
        height: 100vh !important;
    }
}