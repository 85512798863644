.width-6-rem{
    width: 6rem;
}

.height-6-rem{
    height: 6rem;
}

.bg-dark-faded-custom{
    background-color: #000000b3 !important;
}

.border-dark-custom{
    border-color: #000 !important;
}
.navbar-vertical.navbar-expand-xs .navbar-collapse {
    height: auto;
}

.navbar-vertical.navbar-expand-xs {
    bottom: auto;
}

.mobile-navigation {
    display: none;
}

.dropdown-toggle::after {
    content: '';
}

.notifications {
    background: #f4f4f4;
    min-width: 300px;
    margin-left: -250px;
}

.notification-box {
    max-height: 250px;
}

.dropdown.dropdown-hover:hover>.dropdown-menu::before,
.dropdown .dropdown-menu.show::before {
    display: none;
}

.fixed-plugin .fixed-plugin-button {
    background: transparent;
    box-shadow: 0 0 0 0 transparent;
}

.dark-version {
    background: #000 !important;
}

.dark-version .main-content {
    background: #000 !important;
}

.magnify-md {
    transform: scale(1.3);
}

.desktop-search {
    display: flex;
}

.mobile-search {
    display: none;
}

.game-card {
    min-width: 10rem;
    max-width: 10rem;
    min-height: 15rem;
}

.hide-scrollbar {
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
    overflow-y: scroll;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.text-dark {
    color: #000 !important;
}

.text-dark-custom {
    color: #000 !important;
}

.dark-version .text-dark {
    color: #fff !important;
}

.dark-version .text-dark-custom {
    color: #fff !important;
}

.dark-version .card {
    background: linear-gradient(195deg, #66BB6A 0%, #43A047 100%);
}

.share-button {
    width: 2rem !important;
    height: 3.5rem !important;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.nav-section-items .active {
    text-decoration: underline;
}

@media only screen and (max-width: 480px) {
    .navbar-vertical.navbar-expand-xs {
        display: none;
    }

    .notifications {
        position: absolute !important;
        left: 10rem !important;
    }

    .settings {
        left: 4rem !important;
    }

    .mobile-navigation {
        display: flex;
        position: fixed;
        justify-content: space-around;
        bottom: -20px;
        height: 75px;
        z-index: 100;
    }

    .fixed-plugin .fixed-plugin-button {
        bottom: 80px;
    }

    .dropdown.dropdown-hover:hover>.dropdown-menu::before,
    .dropdown .dropdown-menu.show::before {
        left: 8.5rem;
    }

    .settings-dropup {
        margin-top: 0 !important;
        display: flex;
        flex-direction: column-reverse;
    }

    .desktop-search {
        display: none;
    }

    .mobile-search {
        display: flex;
    }

    .share-button {
        bottom: 4rem;
    }
}